<!-- eslint-disable -->
<template>
  <div id="container" class="hfeed wpbf-page">
    <!--Start of header nav bar-->

    <!--End of header nav bar-->
    <div
      data-elementor-type="wp-page"
      data-elementor-id="2184"
      class="elementor elementor-2184"
      data-elementor-settings="[]"
    >
      <div class="elementor-section-wrap">
        <!--Start of Background Slider-->

        <!--End of Background Slider-->
        <section
          class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-section-height-full elementor-section-content-middle elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
          data-particle_enable="false"
          data-particle-mobile-disabled="false"
          data-id="b3b6b9b"
          data-element_type="section"
          data-settings='{"stretch_section":"section-stretched"}'
        >
          <div class="elementor-background-overlay"></div>
          <div class="elementor-container elementor-column-gap-no">
            <div
              class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-69f4a96"
              data-id="69f4a96"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <section
                  class="has_eae_slider elementor-section elementor-inner-section elementor-element elementor-element-b01d0b2 elementor-section-content-space-around elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-particle_enable="false"
                  data-particle-mobile-disabled="false"
                  data-id="b01d0b2"
                  data-element_type="section"
                >
                  <div class="elementor-container elementor-column-gap-no">
                    <div
                      class="has_eae_slider elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-72f0268"
                      data-id="72f0268"
                      data-element_type="column"
                      data-settings='{"background_background":"classic"}'
                    >
                      <div
                        class="elementor-widget-wrap elementor-element-populated"
                      >
                        <div
                          class="elementor-element elementor-element-89f13c6 elementor-button-align-stretch elementor-widget elementor-widget-form"
                          data-id="89f13c6"
                          data-element_type="widget"
                          data-settings='{"button_width":"40","step_next_label":"Next","step_previous_label":"Previous","step_type":"number_text","step_icon_shape":"circle"}'
                          data-widget_type="form.default"
                        >
                          <!--START of Review us Form-->
                          <div class="elementor-widget-container">
                            <form
                              class="elementor-form"
                              method="post"
                              name="Review Us Form"
                            >
                              <input
                                type="hidden"
                                name="post_id"
                                value="2184"
                              />
                              <input
                                type="hidden"
                                name="form_id"
                                value="89f13c6"
                              />
                              <input
                                type="hidden"
                                name="referer_title"
                                value="Review Us"
                              />
                              <input
                                type="hidden"
                                name="queried_id"
                                value="2184"
                              />
                              <div
                                class="elementor-form-fields-wrapper elementor-labels-above"
                              >
                                <div
                                  class="elementor-field-type-step elementor-field-group elementor-column elementor-field-group-1 elementor-col-100"
                                >
                                  <div
                                    class="e-field-step elementor-hidden"
                                    data-label=""
                                    data-previousbutton=""
                                    data-nextbutton=""
                                    data-iconurl=""
                                    data-iconlibrary="fas fa-star"
                                    data-icon=""
                                  ></div>
                                </div>
                                <div
                                  class="elementor-field-type-radio elementor-field-group elementor-column elementor-field-group-1_answer elementor-col-75 elementor-field-required elementor-mark-required"
                                >
                                  <label
                                    for="form-field-1_answer"
                                    class="elementor-field-label"
                                  >
                                    This Feature is coming soon
                                  </label>
                                </div>
                                <div
                                  class="elementor-field-type-step elementor-field-group elementor-column elementor-field-group-2 elementor-col-100"
                                >
                                  <div
                                    class="e-field-step elementor-hidden"
                                    data-label=""
                                    data-previousbutton=""
                                    data-nextbutton=""
                                    data-iconurl=""
                                    data-iconlibrary="fas fa-star"
                                    data-icon=""
                                  ></div>
                                </div>
                              </div>
                            </form>
                          </div>
                          <!--END of Review us Form-->
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RateUs",
  created() {
    // if (!User.loggedIn()) {
    //   this.$router.push({ name: "GuestLogin" });
    // }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
<!-- eslint-enable -->
