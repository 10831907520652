<!-- eslint-disable -->
<template>

  <div id="container" class="hfeed wpbf-page">
  <loader
      object="#ff9633"
      v-if="isLoading"
      color1="#ffffff"
      color2="#999793"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="80"
      name="spinning"
    ></loader>
    <!--START OF HEADER NAV BAR-->
	
    <!--END OF HEADER NAV BAR-->
    <div
      data-elementor-type="wp-page"
      data-elementor-id="1983"
      class="elementor elementor-1983"
      data-elementor-settings="[]"
    >
      <div class="elementor-section-wrap">
        <!--START of BACKGROUND SLIDER-->

        <!--END of BACKGROUND SLIDER-->
        <section
          class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-section-height-min-height elementor-section-content-middle elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
          data-particle_enable="false"
          data-particle-mobile-disabled="false"
          data-id="17739ff"
          data-element_type="section"
          data-settings='{"stretch_section":"section-stretched"}'
        >
          <div class="elementor-background-overlay"></div>
          <div class="elementor-container elementor-column-gap-no">
            <div
              class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7ac1db2"
              data-id="7ac1db2"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <section
                  class="has_eae_slider elementor-section elementor-inner-section elementor-element elementor-element-095ef7d elementor-section-full_width elementor-section-content-middle elementor-section-height-default elementor-section-height-default"
                  data-particle_enable="false"
                  data-particle-mobile-disabled="false"
                  data-id="095ef7d"
                  data-element_type="section"
                >
                  <div class="elementor-container elementor-column-gap-no">
                    <div
                      class="has_eae_slider elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-d4412b2"
                      data-id="d4412b2"
                      data-element_type="column"
                      data-settings='{"background_background":"classic"}'
                    >
                      <div
                        class="elementor-widget-wrap elementor-element-populated"
                      >
                        <!--START of CHECKOUT ITEM TABLE-->
                        <div
                          class="elementor-element elementor-element-ff28744 elementor-widget elementor-widget-tp-table"
                          data-id="ff28744"
                          data-element_type="widget"
                          data-widget_type="tp-table.default"
                        >
                          <div class="elementor-widget-container">
                            <div itemscope class="plus-table-wrapper" style="max-height: 350px; overflow-y: scroll;">
                              <table
                                id="plus-table-id-ff28744"
                                class="plus-table plus-text-break plus-column-rules"
                                data-sort-table="no"
                                data-show-entry="no"
                                data-searchable="no"
                              >
                                <thead>
                                  <tr class="plus-table-row">
                                    <th
                                      id="tooltip61ca7f619268f"
                                      data-sort="0"
                                      class="sort-this elementor-repeater-item-ade8803 plus-table-col"
                                      data-local="true"
                                      scope="col"
                                    >
                                      <span class="sort-style">
                                        <span class="plus-table__text">
                                          <span
                                            class="plus-table__text-inner"
                                          ></span>
                                        </span>
                                      </span>
                                    </th>
                                    <th
                                      id="tooltip61ca7f61926a1"
                                      data-sort="1"
                                      class="sort-this elementor-repeater-item-0f9cc67 plus-table-col"
                                      data-local="true"
                                      scope="col"
                                    >
                                      <span class="sort-style">
                                        <span class="plus-table__text">
                                          <span
                                            class="plus-table__text-inner"
                                          ></span>
                                        </span>
                                      </span>
                                    </th>
                                    <th
                                      id="tooltip61ca7f61926ae"
                                      data-sort="2"
                                      class="sort-this elementor-repeater-item-9e20a5c plus-table-col"
                                      data-local="true"
                                      scope="col"
                                    >
                                      <span class="sort-style">
                                        <span class="plus-table__text">
                                          <span class="plus-table__text-inner"
                                            >{{lang.checkOut.items}}</span
                                          >
                                        </span>
                                      </span>
                                    </th>
                                    <th
                                      id="tooltip61ca7f61926ba"
                                      data-sort="3"
                                      class="sort-this elementor-repeater-item-8c532cc plus-table-col"
                                      data-local="true"
                                      scope="col"
                                    >
                                      <span class="sort-style">
                                        <span class="plus-table__text">
                                          <span class="plus-table__text-inner">
                                          </span>
                                        </span>
                                      </span>
                                    </th>
                                    <th
                                      id="tooltip61ca7f61926c5"
                                      data-sort="4"
                                      class="sort-this elementor-repeater-item-a657855 plus-table-col"
                                      data-local="true"
                                      scope="col"
                                    >
                                      <span class="sort-style">
                                        <span class="plus-table__text">
                                          <span class="plus-table__text-inner"
                                            >{{lang.checkOut.qty}}</span
                                          >
                                        </span>
                                      </span>
                                    </th>
                                    <th
                                      id="tooltip61ca7f61926d1"
                                      data-sort="5"
                                      class="sort-this elementor-repeater-item-46795f3 plus-table-col"
                                      data-local="true"
                                      scope="col"
                                    >
                                      <span class="sort-style">
                                        <span class="plus-table__text">
                                          <span class="plus-table__text-inner"
                                            >{{lang.checkOut.subTotal}}</span
                                          >
                                        </span>
                                      </span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <!-- ROWS -->

                                  <tr
                                    v-for="cartItem in cart.items"
                                    :key="cartItem.id"
                                    data-entry="1"
                                    class="plus-table-row"
                                  >
                                    <td
                                      id="tooltip61ca7f61926f3"
                                      class="plus-table-col elementor-repeater-item-909e904"
                                    >
                                      <a @click="removeCart(cartItem.item_id)" class="tb-col-link">
                                        <span class="plus-table__text">
                                          <span class="plus-align-icon--left">
                                            <i class="fa fa-remove"></i>
                                          </span>
                                        </span>
                                      </a>
                                    </td>
                                    <td
                                      id="tooltip61ca7f6192dbf"
                                      class="plus-table-col elementor-repeater-item-6323f50"
                                    >
                                      <span class="plus-table__text">
                                       
										                    <img :src="baseUrl + cartItem.image" class="plus-col-img--left" title="91_trcybzs433rmm_t800x800" alt="91_trcybzs433rmm_t800x800">
                                      </span>
                                      
                                    </td>
                                    <td
                                      id="tooltip61ca7f6192df0"
                                      class="plus-table-col elementor-repeater-item-4a20cff"
                                      data-title="Item"
                                    >
                                      <span class="plus-table__text">
                                        <span class="plus-table__text-inner"
                                          >{{ cartItem.name }}</span
                                        >
                                      </span>
                                    </td>
                                    <td
                                      id="tooltip61ca7f6192e03"
                                      class="plus-table-col elementor-repeater-item-23993e6"
                                      data-title="Price"
                                    >
                                      <span class="plus-table__text">
                                        <span class="plus-table__text-inner">
                                        </span>
                                      </span>
                                    </td>
                                    <td
                                      id="tooltip61ca7f6192e16"
                                      class="plus-table-col elementor-repeater-item-21b7c99"
                                      data-title="Qty"
                                    >
                                      <span class="plus-table__text">
                                        <span class="plus-table__text-inner"
                                          >{{ cartItem.quantity }}</span
                                        >
                                      </span>
                                    </td>
                                    <td
                                      id="tooltip61ca7f6192e34"
                                      class="plus-table-col elementor-repeater-item-9a4799b"
                                      data-title="Sub Total"
                                    >
                                      <span class="plus-table__text">
                                        <span class="plus-table__text-inner"
                                          >{{ cartItem.purchased_price * cartItem.quantity  }} {{lang.checkOut.le}}</span
                                        >
                                      </span>
                                    </td>
                                  </tr>
                                  <tr data-entry="2" class="plus-table-row">
                                    <td
                                      id="tooltip61ca7f6192e5b"
                                      class="plus-table-col elementor-repeater-item-18e4b68"
                                      colspan="5"
                                    >
                                      <span class="plus-table__text">
                                        <span class="plus-table__text-inner"
                                          >{{lang.checkOut.subTotal}}</span
                                        >
                                      </span>
                                    </td>
                                    <td
                                      id="tooltip61ca7f6192e69"
                                      class="plus-table-col elementor-repeater-item-1eb938f"
                                    >
                                      <span class="plus-table__text">
                                        <span class="plus-table__text-inner"
                                          > {{ cart.subtotal }} {{lang.checkOut.le}}</span
                                        >
                                      </span>
                                    </td>
                                  </tr>
								  <tr data-entry="2" class="plus-table-row">
                                    <td
                                      id="tooltip61ca7f6192e5b"
                                      class="plus-table-col elementor-repeater-item-18e4b68"
                                      colspan="5"
                                    >
                                      <span class="plus-table__text">
                                        <span class="plus-table__text-inner"
                                          >{{lang.checkOut.tax}}</span
                                        >
                                      </span>
                                    </td>
                                    <td
                                      id="tooltip61ca7f6192e69"
                                      class="plus-table-col elementor-repeater-item-1eb938f"
                                    >
                                      <span class="plus-table__text">
                                        <span class="plus-table__text-inner"
                                          >{{ cart.tax   }}%</span
                                        >
                                      </span>
                                    </td>
                                  </tr>
								  <tr data-entry="2" class="plus-table-row">
                                    <td
                                      id="tooltip61ca7f6192e5b"
                                      class="plus-table-col elementor-repeater-item-18e4b68"
                                      colspan="5"
                                    >
                                      <span class="plus-table__text">
                                        <span class="plus-table__text-inner"
                                          >{{lang.checkOut.grandTotal}}</span
                                        >
                                      </span>
                                    </td>
                                    <td
                                      id="tooltip61ca7f6192e69"
                                      class="plus-table-col elementor-repeater-item-1eb938f"
                                    >
                                      <span class="plus-table__text">
                                        <span class="plus-table__text-inner"
                                          >{{ cart.total_price }} {{lang.checkOut.le}}</span
                                        >
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <!--END of CHECKOUT ITEM TABLE-->
                        <div
                          class="elementor-element elementor-element-9586c17 elementor-widget-elementskit-dual-button-end elementor-widget-elementskit-dual-button-mobile-end elementor-widget elementor-widget-elementskit-dual-button"
                          data-id="9586c17"
                          data-element_type="widget"
                          data-widget_type="elementskit-dual-button.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="ekit-wid-con">
                              <div class="ekit-element-align-wrapper">
                                <div class="ekit_double_button">
                                  <button
                                    class="ekit-double-btn ekit-double-btn-one"
                                    @click="checkOut()"
                                    >{{lang.checkOut.placeYourOrder}}<i
                                      aria-hidden="true"
                                      class="icon icon-shopping-cart-1"
                                    ></i>
                                  </button>
                                  <router-link
                                    class="ekit-double-btn ekit-double-btn-two"
                                    :to="{ name: 'Menu', params: {menuId: restaurant_id} }"
                                  >
                                    <i
                                      aria-hidden="true"
                                      class="fas fa-undo"
                                    ></i
                                    >{{lang.checkOut.backToBooking}}</router-link
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Checkout",
  data() {
    return {
       hotelId: 1,
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      restaurant_id: this.$route.params.restId.split("_")[0],
      booking_id: this.$route.params.restId.split("_")[1],
      orderId: this.$route.params.orderId,
	  guestData:[],
      cart: {
        
        items: [],
        tax: 0,
        total_price: 0,
        subtotal:0,

      },
	  isLoading: false,
        lang:"",
    }
  },
  created() {
    this.setLang();
    this.getCartData();
	this.getGuestInfo();
  },
  methods: {
    getCartData(){
      this.axios
      .get(
        process.env.VUE_APP_API_BASE_URL +
        "api/order-data-not-checkout?api_key="+
          process.env.VUE_APP_API_KEY +
          "&lang=" +
          localStorage.getItem('localization') +
          "&api_token=" +
                 localStorage.getItem("token") +
          "&restaurant_id="+this.restaurant_id+
            "&hotel_id=1"
      )
      .then((res) => {
       console.log(res)
      if(res.data.data){
       console.log('y')
        this.cart = res.data.data
        this.cart.items.forEach((d, index) => {
          this.itemsInCart.push(d.id)
        });

      }else{
       console.log('n')

      }
       console.log(res.data.data)

      })
      .catch((error) => console.log(error));
    },
    setLang() {
      this.axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "api/frontend-keywords?api_key=" +
            process.env.VUE_APP_API_KEY +
            "&lang=" +
            localStorage.getItem("localization")
        )
        .then(
          (res) => {
            console.log(res)
            this.lang = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
   removeCart(item) {
      this.isLoading = true;

      var formData = {
        
        api_key: process.env.VUE_APP_API_KEY,
        lang: localStorage.getItem('localization'),
        order_id: this.cart.id,
        api_token:localStorage.getItem("token") ,
        item_id: item,
        order_id: this.cart.id
      };
        // api_token:localStorage.getItem("token") ,

      this.axios
        .post(process.env.VUE_APP_API_BASE_URL + "api/remove-order-item", formData)
        .then((res) => {
          this.isLoading = false;
          this.getCartData();
          console.log(res.data)
          // this.$notify({
          //   group: "foo",
          //   title: "Success",
          //   text: "item removed successfully ",
          //   type: "success",
          // });
        })
        .catch((error) => {
          this.isLoading = false;

          this.errors = error;
          this.$notify({
            group: "foo",
            title: "Invalid Login",
            text: "Something error in remove from cart please check your inputs and try again ",
            type: "error",
          });
          console.log(error);
        });

      // console.log(this.cart.cartItems)
    },getGuestInfo(){
		this.axios
      .get(
        process.env.VUE_APP_API_BASE_URL +
          "api/user-profile?api_key=" +
          process.env.VUE_APP_API_KEY +
          "&lang=" +
          process.env.VUE_APP_LANG +
          "&api_token=" +
                 localStorage.getItem("token") +
          "&hotel_id=1&restaurant_id=1"
      )
      .then((res) => {
		  console.log(" ----------- Guest Info ---------");
		  console.log(res);
        this.guestData.guestName = res.data.data.first_name + ' ' + res.data.data.last_name
        this.guestData.guestRoom = res.data.data.more_data.room_number
        this.guestData.guestReservation = res.data.data.more_data.reservation
        this.guestData.guestArrivalData = res.data.data.more_data.checkin_date
        this.guestData.guestDepartureDate = res.data.data.more_data.checkout_date
		this.guestData.companions = res.data.data.more_data.companions
        this.isLoading = false;


 
      })
      .catch((error) => console.log(error));
	},
    checkOut(){
      this.isLoading = true;
        var formData = {
          api_key: process.env.VUE_APP_API_KEY,
          lang: localStorage.getItem('localization'),
          api_token: localStorage.getItem("token"),
          order_id: this.orderId,
          booking_id: localStorage.getItem('currentBookingID'),
		  number_of_companions:this.guestData.companions
          

        };

        this.axios
          .post(
            process.env.VUE_APP_API_BASE_URL + "api/order-checkout",
            formData
          )
          .then((res) => {
            this.isLoading = false;
            console.log(res.data);
            // this.$notify({
            //   group: "foo",
            //   title: res.data.message,
            //   text: res.data.message,
            //   type: "success",
            // });
          this.$router.push({ name: "QrPrint" , id:this.$route.params.id , orderId:this.o});

          })
          .catch((error) => {
            this.isLoading = false;

            this.errors = error;
            this.$notify({
              group: "foo",
              title: "Invalid",
              text: "Something error in add to cart please check your inputs and try again ",
              type: "error",
            });
            console.log(error);
          });
    }
  },
   
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
<style>
#plus-table-id-ff28744 th,
#plus-table-id-ff28744 th .plus-table__text {
  margin: 0 auto;
  text-align: left;
  margin-left: 0;
}
</style>

<!-- eslint-enable -->
