import Vue from 'vue';
import Vuex from 'vuex';
import router from './router';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    user: localStorage.getItem('user') || null,
    language: localStorage.getItem('localization') || 'en',
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    SET_LANGUAGE(state, language) {
      state.language = language;
    },
  },
  actions: {
    logout({ commit }) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      commit('SET_USER', null);
      // Redirect to GuestLogin route
      //this._vm.$router.push({ name: 'GuestLogin' });
	  //window.location=""
    },
    resetLanguage({ commit }) {
      const defaultLanguage = 'en'; // Set your default language here
     // this._vm.$i18n.locale = defaultLanguage;
      localStorage.setItem('localization', defaultLanguage);
      commit('SET_LANGUAGE', defaultLanguage);
     // this._vm.$router.push({
      //  params: { lang: defaultLanguage }
      //});
      location.reload();
    },
  },
});

export default store;
