class Token {
    isValid(token){
        if (token) {

         return true;
        }
        return false
    }
    payload(token){
        const payload = token.split('.')[1]
        return  this.decode(payload)
    }

    decode(payload){
        return JSON.parse(atob(payload))
    }

}
export default Token = new Token();
