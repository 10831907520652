import Vue from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import store from './store';

Vue.config.productionTip = false;
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

// Import Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import i18n from './i18n';
// import user class
import User from './Helpers/User';
window.User = User;
Vue.use(VueAxios, axios);
// import preloader
import loader from 'vue-ui-preloader';
// import notifications
import Notifications from 'vue-notification';
// import notification class
import Notifcation from './Helpers/Notification';
window.Notification = Notifcation;
/*	  detectBrowserLanguage() {
      // Get the browser's preferred language
        const primaryLanguage = navigator.language.substr(0, 2).toLowerCase();
  
  // Map the primary language code to the specified languages
  switch (primaryLanguage) {
    case 'ar':
      this.browserLanguage = 'ar'; // Arabic
      break;
    case 'en':
      this.browserLanguage = 'en'; // English
      break;
    case 'it':
      this.browserLanguage = 'it'; // Italian
      break;
    case 'de':
      this.browserLanguage = 'de'; // German
      break;
    case 'fr':
      this.browserLanguage = 'fr'; // French
      break;
    default:
      // Default to English if the browser language is not one of the specified languages
      this.browserLanguage = 'en'; // English
	   this.$i18n.locale = this.browserLanguage;
      localStorage.setItem('localization', locale);
      
  }
*/
Vue.use(Notifications);
Vue.use(loader);
router.beforeEach((to, from, next) => {
  if (to.path === '/') {
 //   router.push('/en');
  }
  next();
});

new Vue({
  store,
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
