<!-- eslint-disable -->
<template>
  <div
    data-elementor-type="wp-page"
    data-elementor-id="2166"
    class="elementor elementor-2166"
    data-elementor-settings="[]"
    style="background-color: white; padding: 50px ; z-index:99;"
  >
    <div class="elementor-section-wrap">
      <section
        class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-3eb5b41 elementor-section-height-full elementor-section-items-top elementor-section-boxed elementor-section-height-default"
        data-particle_enable="false"
        data-particle-mobile-disabled="false"
        data-id="3eb5b41"
        data-element_type="section"
    style="z-index:99;"

        
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-17f0ce6"
            data-id="17f0ce6"
            data-element_type="column"
            data-settings='{"background_background":"classic"}'

          >
            <div
              class="elementor-widget-wrap elementor-element-populated"
              style="padding: 20px"
            >
              <div
                class="elementor-element elementor-element-13ab662 elementor-align-left elementor-widget__width-auto elementor-widget elementor-widget-button"
                data-id="13ab662"
                data-element_type="widget"
                data-widget_type="button.default"
              >
                <div class="elementor-widget-container">
                  <div class="elementor-button-wrapper">
                    <a
                      href="#"
                      class="elementor-button-link elementor-button elementor-size-sm"
                      role="button"
                    >
                      <span class="elementor-button-content-wrapper">
                        <span class="elementor-button-text">Screenshot</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div
                class="elementor-element elementor-element-efcf9a5 elementor-align-left elementor-widget__width-auto elementor-widget elementor-widget-button"
                data-id="efcf9a5"
                data-element_type="widget"
                data-widget_type="button.default"
              >
                <div class="elementor-widget-container">
                  <div class="elementor-button-wrapper">
                    <a
                      href="#"
                      class="elementor-button-link elementor-button elementor-size-sm"
                      role="button"
                    >
                      <span class="elementor-button-content-wrapper">
                        <span class="elementor-button-text">Print</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div
                class="elementor-element elementor-element-c1f6a1c elementor-widget elementor-widget-image"
                data-id="c1f6a1c"
                data-element_type="widget"
                data-widget_type="image.default"
              >
                <div class="elementor-widget-container">
                  <a href="index.html">
                    <img
                      width="518"
                      height="268"
                      :src="hotelLogo"
                      class="attachment-full size-full"
                      alt=""
                      loading="lazy"
                      
                      sizes="(max-width: 518px) 100vw, 518px"
                    />
                  </a>
                </div>
              </div>
              <div
                class="elementor-element elementor-element-27af712 elementor-widget elementor-widget-bdt-qrcode"
                data-id="27af712"
                data-element_type="widget"
                data-widget_type="bdt-qrcode.default"
              >
                <div class="elementor-widget-container">
                  <div
                    class="bdt-qrcode"
                    data-settings='{"render":"canvas","ecLevel":"H","minVersion":4,"fill":"#333333","text":"#content","size":100,"mSize":11,"mPosX":50,"mPosY":50,"background":"transparent"}'
                  ></div>
                </div>
              </div>
              <div
                class="elementor-element elementor-element-dddb170 elementor-widget elementor-widget-tp-table"
                data-id="dddb170"
                data-element_type="widget"
                data-widget_type="tp-table.default"
              >
                <div class="elementor-widget-container">
                  <div itemscope class="plus-table-wrapper">
                    <table
                      id="plus-table-id-dddb170"
                      class="plus-table plus-text-break plus-column-rules"
                      data-sort-table="no"
                      data-show-entry="no"
                      data-searchable="no"
                    >
                      <tbody>
                        <!-- ROWS -->
                        <tr data-entry="1" class="plus-table-row">
                          <td
                            id="tooltip61ca7f1ba189f"
                            class="plus-table-col elementor-repeater-item-07eeede"
                          >
                            <span class="plus-table__text">
                              <span class="plus-table__text-inner"
                                >Mr. {{ orderData.guest.first_name }} , {{ orderData.guest.last_name }}</span
                              >
                            </span>
                          </td>
                          <td
                            id="tooltip61ca7f1ba18b9"
                            class="plus-table-col elementor-repeater-item-afa0769"
                          >
                            <span class="plus-table__text"> </span>
                          </td>
                          <td
                            id="tooltip61ca7f1ba18c6"
                            class="plus-table-col elementor-repeater-item-ef225e6"
                          >
                            <span class="plus-table__text">
                              <span class="plus-table__text-inner">Room#</span>
                            </span>
                          </td>
                          <td
                            id="tooltip61ca7f1ba18d3"
                            class="plus-table-col elementor-repeater-item-8fef659"
                          >
                            <span class="plus-table__text">
                              <span class="plus-table__text-inner">{{orderData.guest.more_data.room_number}}</span>
                            </span>
                          </td>
                        </tr>
                        <tr data-entry="2" class="plus-table-row">
                          
                          <td
                            id="tooltip61ca7f1ba19c4"
                            class="plus-table-col elementor-repeater-item-3259690"
                          >
                            <span class="plus-table__text">
                              <span class="plus-table__text-inner">Day</span>
                            </span>
                          </td>
                          <td
                            id="tooltip61ca7f1ba19cf"
                            class="plus-table-col elementor-repeater-item-2f72130"
                          >
                            <span class="plus-table__text">
                              <span class="plus-table__text-inner">{{orderDay}}</span>
                            </span>
                          </td> 
                           <td
                            id="tooltip61ca7f1ba1986"
                            class="plus-table-col elementor-repeater-item-a691812"
                          >
                            <span class="plus-table__text">
                              <span class="plus-table__text-inner"
                                >Booking No.</span
                              >
                            </span>
                          </td>
                          <td
                            id="tooltip61ca7f1ba1993"
                            class="plus-table-col elementor-repeater-item-5ca2f0d"
                          >
                            <span class="plus-table__text">
                              <span class="plus-table__text-inner">{{ orderData.book_number}}</span>
                            </span>
                          </td>
                        </tr>
                        <tr data-entry="3" class="plus-table-row">
                          <td
                            id="tooltip61ca7f1ba1927"
                            class="plus-table-col elementor-repeater-item-348eaa7"
                          >
                            <span class="plus-table__text">
                              <span class="plus-table__text-inner">Date:</span>
                            </span>
                          </td>
                          <td
                            id="tooltip61ca7f1ba1933"
                            class="plus-table-col elementor-repeater-item-2613432"
                          >
                            <span class="plus-table__text">
                              <span class="plus-table__text-inner"
                                >{{orderData.date}}</span
                              >
                            </span>
                          </td>
                          <td
                            id="tooltip61ca7f1ba193e"
                            class="plus-table-col elementor-repeater-item-056c82b"
                          >
                            <span class="plus-table__text">
                              <span class="plus-table__text-inner">Time</span>
                            </span>
                          </td>
                          <td
                            id="tooltip61ca7f1ba194b"
                            class="plus-table-col elementor-repeater-item-dd1d052"
                          >
                            <span class="plus-table__text">
                              <span class="plus-table__text-inner">{{ orderData.time.time }}</span>
                            </span>
                          </td>
                        </tr>
          
                        <tr data-entry="5" class="plus-table-row">
                          <td
                            id="tooltip61ca7f1ba19aa"
                            class="plus-table-col elementor-repeater-item-2e67d6d"
                          >
                            <span class="plus-table__text">
                              <span class="plus-table__text-inner"
                                >Reservation#</span
                              >
                            </span>
                          </td>
                          <td
                            id="tooltip61ca7f1ba19b7"
                            class="plus-table-col elementor-repeater-item-9ad4875"
                          >
                            <span class="plus-table__text">
                              <span class="plus-table__text-inner">{{orderData.guest.more_data.reservation}}</span>
                            </span>
                          </td>
                           
                           
                        </tr>
                      </tbody>
                    </table>
                    <div class="elementor-widget-container">
                            <div itemscope class="plus-table-wrapper">
                              <table
                                id="plus-table-id-ff28744"
                                class="plus-table plus-text-break plus-column-rules"
                                data-sort-table="no"
                                data-show-entry="no"
                                data-searchable="no"
                              >
                              
                                <thead>
                                <tr data-entry="2" class="plus-table-row">
                                    <td
                                      id="tooltip61ca7f6192e5b"
                                      class="plus-table-col elementor-repeater-item-18e4b68"
                                      colspan="5"
                                      style="text-align:center;background-color: #f5f5f7;"
                                    >
                                      <span class="plus-table__text">
                                        <span class="plus-table__text-inner"
                                          >Order Items</span
                                        >
                                      </span>
                                    </td>
                                    
                                  </tr>
                                  <tr class="plus-table-row">
                                    
                              
                                    <th
                                      id="tooltip61ca7f61926ae"
                                      data-sort="1"
                                      class="sort-this elementor-repeater-item-9e20a5c plus-table-col"
                                      data-local="true"
                                      scope="col"
                                    >
                                      <span class="sort-style">
                                        <span class="plus-table__text">
                                          <span class="plus-table__text-inner"
                                            >Item</span
                                          >
                                        </span>
                                      </span>
                                    </th>
                                    <th
                                      id="tooltip61ca7f61926ba"
                                      data-sort="3"
                                      class="sort-this elementor-repeater-item-8c532cc plus-table-col"
                                      data-local="true"
                                      scope="col"
                                    >
                                      <span class="sort-style">
                                        <span class="plus-table__text">
                                          <span class="plus-table__text-inner">
                                          </span>
                                        </span>
                                      </span>
                                    </th>
                                    <th
                                      id="tooltip61ca7f61926c5"
                                      data-sort="4"
                                      class="sort-this elementor-repeater-item-a657855 plus-table-col"
                                      data-local="true"
                                      scope="col"
                                    >
                                      <span class="sort-style">
                                        <span class="plus-table__text">
                                          <span class="plus-table__text-inner"
                                            >Qty</span
                                          >
                                        </span>
                                      </span>
                                    </th>
                                    <th
                                      id="tooltip61ca7f61926d1"
                                      data-sort="5"
                                      class="sort-this elementor-repeater-item-46795f3 plus-table-col"
                                      data-local="true"
                                      scope="col"
                                    >
                                      <span class="sort-style">
                                        <span class="plus-table__text">
                                          <span class="plus-table__text-inner"
                                            >Sub Total</span
                                          >
                                        </span>
                                      </span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <!-- ROWS -->

                                  <tr
                                    v-for="cartItem in orderData.order.items"
                                    :key="cartItem.id"
                                    data-entry="1"
                                    class="plus-table-row"
                                  >
                                    
              
                                    <td
                                      id="tooltip61ca7f6192df0"
                                      class="plus-table-col elementor-repeater-item-4a20cff"
                                      data-title="Item"
                                    >
                                      <span class="plus-table__text">
                                        <span class="plus-table__text-inner"
                                          >{{ cartItem.name }}</span
                                        >
                                      </span>
                                    </td>
                                    <td
                                      id="tooltip61ca7f6192e03"
                                      class="plus-table-col elementor-repeater-item-23993e6"
                                      data-title="Price"
                                    >
                                      <span class="plus-table__text">
                                        <span class="plus-table__text-inner">
                                        </span>
                                      </span>
                                    </td>
                                    <td
                                      id="tooltip61ca7f6192e16"
                                      class="plus-table-col elementor-repeater-item-21b7c99"
                                      data-title="Qty"
                                    >
                                      <span class="plus-table__text">
                                        <span class="plus-table__text-inner"
                                          >{{ cartItem.quantity }}</span
                                        >
                                      </span>
                                    </td>
                                    <td
                                      id="tooltip61ca7f6192e34"
                                      class="plus-table-col elementor-repeater-item-9a4799b"
                                      data-title="Sub Total"
                                    >
                                      <span class="plus-table__text">
                                        <span class="plus-table__text-inner"
                                          >{{ cartItem.price * cartItem.quantity  }} L.E</span
                                        >
                                      </span>
                                    </td>
                                  </tr>
                                  <tr data-entry="2" class="plus-table-row">
                                    <td
                                      id="tooltip61ca7f6192e5b"
                                      class="plus-table-col elementor-repeater-item-18e4b68"
                                      colspan="5"
                                      style="text-align:center;background-color: #f5f5f7;"
                                    >
                                      <span class="plus-table__text">
                                        <span class="plus-table__text-inner"
                                          >Order Summary</span
                                        >
                                      </span>
                                    </td>
                                    
                                  </tr>
                                  <tr data-entry="2" class="plus-table-row">
                                    <td
                                      id="tooltip61ca7f6192e5b"
                                      class="plus-table-col elementor-repeater-item-18e4b68"
                                      colspan="1"
                                    >
                                      <span class="plus-table__text">
                                        <span class="plus-table__text-inner"
                                          >Sub Total</span
                                        >
                                      </span>
                                    </td>
                                    <td
                                      id="tooltip61ca7f6192e69"
                                      class="plus-table-col elementor-repeater-item-1eb938f"
                                      colspan="3"

                                    >
                                      <span class="plus-table__text">
                                        <span class="plus-table__text-inner"
                                          > {{ orderData.order.subtotal }} L.E</span
                                        >
                                      </span>
                                    </td>
                                  </tr>
								                  <tr data-entry="2" class="plus-table-row">
                                    <td
                                      id="tooltip61ca7f6192e5b"
                                      class="plus-table-col elementor-repeater-item-18e4b68"
                                      colspan="1"
                                    >
                                      <span class="plus-table__text">
                                        <span class="plus-table__text-inner"
                                          >Tax</span
                                        >
                                      </span>
                                    </td>
                                    <td
                                      id="tooltip61ca7f6192e69"
                                      class="plus-table-col elementor-repeater-item-1eb938f"
                                      colspan="3"

                                    >
                                      <span class="plus-table__text">
                                        <span class="plus-table__text-inner"
                                          >{{ orderData.order.tax   }}%</span
                                        >
                                      </span>
                                    </td>
                                  </tr>
								                  <tr data-entry="2" class="plus-table-row">
                                    <td
                                      id="tooltip61ca7f6192e5b"
                                      class="plus-table-col elementor-repeater-item-18e4b68"
                                      colspan="1"
                                    >
                                      <span class="plus-table__text">
                                        <span class="plus-table__text-inner"
                                          >Grand Total</span
                                        >
                                      </span>
                                    </td>
                                    <td
                                      id="tooltip61ca7f6192e69"
                                      class="plus-table-col elementor-repeater-item-1eb938f"
                                      colspan="3"

                                    >
                                      <span class="plus-table__text">
                                        <span class="plus-table__text-inner"
                                          >{{ orderData.order.total_price }} L.E</span
                                        >
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                  </div>
                </div>
              </div>
              <div
                class="elementor-element elementor-element-1f15da0 elementor-widget elementor-widget-heading"
                data-id="1f15da0"
                data-element_type="widget"
                data-widget_type="heading.default"
              >
                <div class="elementor-widget-container">
                  <h2 class="elementor-heading-title elementor-size-default">
                    Total:
                  </h2>
                </div>
              </div>
              <div
                class="elementor-element elementor-element-d6f98ec elementor-widget elementor-widget-heading"
                data-id="d6f98ec"
                data-element_type="widget"
                data-widget_type="heading.default"
              >
                <div class="elementor-widget-container">
                  <h2 class="elementor-heading-title elementor-size-default">
                    Complimentary
                  </h2>
                </div>
              </div>
              <div
                class="elementor-element elementor-element-f99d7f4 elementor-widget elementor-widget-text-editor"
                data-id="f99d7f4"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div class="elementor-widget-container">
                  <p>
                    • In all restaurants dress code is “smart casual elegant”.
                    Dear gentlemen please avoid short pants in general and<br />please
                    wear trousers.<br />• Минимальный дресс код: повседневная
                    одежда и обувь, приветствуется элегантный повседневный стиль
                    в<br />одежде (для мужчин длинные брюки вечером).<br />يرجى
                    ارتداء الملابس المناسبة داخل جميع المطاعم وعدم ارتداء ملابس
                    البحر او حافى القدمين لا يسمح بارتداء الشورت او •<br />.
                    الشبشب او الترينجات الرياضيه<br />• Es gilt in allen
                    Restaurants die Kleiderordnung “schick, lässig, elegant”.Der
                    Mann sollte eine lange Hose tragen<br />• Dans tous les
                    restaurants, le code vestimentaire est décontracté et
                    élégant.<br />• Vi chiediamo di indossare in tutti i
                    ristoranti un abbigliamento appropriato, “smart casual
                    elegant”.
                  </p>
                </div>
              </div>
              <div
                class="elementor-element elementor-element-d9db391 elementor-widget elementor-widget-heading"
                data-id="d9db391"
                data-element_type="widget"
                data-widget_type="heading.default"
              >
                <div class="elementor-widget-container">
                  <h2 class="elementor-heading-title elementor-size-default">
                    Thank You
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "BookingDetails",
  data(){
    return{
      hotelLogo: localStorage.getItem("hotelLogo"),
      orderData:'',
      orderDay:''
    }
  },
  created(){
      this.getOrderData()

  },
  methods:{
     getOrderData() {
      this.axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "api/booking-details?api_key=" +
            process.env.VUE_APP_API_KEY +
            "&lang=" +
            process.env.VUE_APP_LANG +
            "&api_token=" +
            localStorage.getItem("token") +
            // "8KxcnRyDoGwOBhjbjhrrx3vLuhUAoY3h28RO6indKBiBdKuL2IfZJ8bbrskX"+
            "&booking_id=" +
            this.$route.params.bookId
        )
        .then((res) => {
          if (res.data.data) {
            this.orderData =res.data.data
            const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];

            const d = new Date(this.orderData.date);
            let day = weekday[d.getDay()];
             
           this.orderDay =day
          } else {
            console.log("n");
          }
        })
        .catch((error) => console.log(error));
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
<!-- eslint-enable -->
