class Notification {
  staticwarnMessage(title, text) {
    this.$notify({
      group: "foo",
      title: title,
      text: text,
      type: "warn",
    });
  }
  successMessage(title, text) {
    this.$notify({
      group: "foo",
      title: title,
      text: text,
      type: "success",
    });
  }
  errorMessage(title, text) {
    this.$notify({
      group: "foo",
      title: title,
      text: text,
      type: "error",
    });
  }
  infoMessage(title, text) {
    this.$notify({
      group: "foo",
      title: title,
      text: text,
      type: "error",
    });
  }
}
export default Notification = new Notification();
