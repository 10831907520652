<template>
  <div id="app" class="">
   <swiper
      :slides-per-view="2"
      :space-between="0"
      :loop="false"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
      class="eael-tabs-nav"
      style="background:#4e4e4e"
    >
    <swiper-slide 
        id="appetizers"
        v-for="menucategory in menuCategories"
        :key="menucategory.categoryId"
        class="active-default eael-tab-inline-icon"
        v-bind:class="{
          active: menucategory.isActiveTab,
        }"
        @click="activeTab(menucategory)"
        style="width:12vw;height:7vh;background:#4e4e4e"
         >
        <img
          :src="menucategory.categoryIcon"
          alt=""
          style="width:20px;height:20px"
        />
        <span
          class="eael-tab-title title-after-icon"
          style="color:#fff;"
          >{{ menucategory.categoryName }}</span
        >
    </swiper-slide>
    </swiper>

    <!-- <swiper
      :slides-per-view="3"
      :space-between="30"
      loop
      @swiper="onSwiper"
      @slideChange="onSlideChange"
    >
      <swiper-slide
        v-for="n in 5"
        :key="n"
        class="test"
        :class="{test_2: true}"
      >
        <div>{{ 34+n }}</div>
        <img
          :src="getImageUrl(34+n)"
          width="600"
          height="400"
          class="img-fluid w-100 mx-auto"
          blank="true"
        >
      </swiper-slide>
    </swiper> -->

    <!-- <swiper
      :slides-per-view="3"
      :space-between="30"
      :loop="false"
      :pagination="true"
      :navigation="true"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
    >
      <swiper-slide
        v-for="n in 5"
        :key="n"
        class="test"
        :class="{test_2: true}"
      >
        <div>{{ 34+n }}</div>
        <img
          :src="getImageUrl(34+n)"
          width="600"
          height="400"
          class="img-fluid w-100 mx-auto"
          blank="true"
        >
      </swiper-slide>
    </swiper> -->
  </div>
</template>

<script>
// Import Swiper Vue.js components
import { Navigation, Pagination } from 'swiper'

import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'

// Import Swiper styles
import 'swiper/swiper-bundle.css'

SwiperCore.use([Navigation, Pagination])

export default {
  props: ['foo'],
  name: 'test',
  data(){
    return {
      menuCategories: [
        {
          categoryId: 1,
          categoryName: "Appetizers",
          categoryIcon: "images/appetizer.png",
          isActiveTab: true,
          categoryItems: [
            {
              itemId: 1,
              itemName: "ITEM NAMES",
              itemDescription:
                "Item Description here, Item Description here, Item Description here, Item Description here, Item Description here,",
              itemPrice: 90.99,
              itemPriceCurency: "L.E",
              itemImg: "images/Meal.jpg",
            },
            {
              itemId: 2,
              itemName: "ITEM NAMES2",
              itemDescription:
                "Item Description here, Item Description here, Item Description here, Item Description here, Item Description here,",
              itemPrice: 90.99,
              itemPriceCurency: "L.E",
              itemImg: "images/Meal.jpg",
            },
            {
              itemId: 3,
              itemName: "ITEM NAMES",
              itemDescription:
                "Item Description here, Item Description here, Item Description here, Item Description here, Item Description here,",
              itemPrice: 90.99,
              itemPriceCurency: "L.E",
              itemImg: "images/Meal.jpg",
            },
            {
              itemId: 4,
              itemName: "ITEM NAMES",
              itemDescription:
                "Item Description here, Item Description here, Item Description here, Item Description here, Item Description here,",
              itemPrice: 90.99,
              itemPriceCurency: "L.E",
              itemImg: "images/Meal.jpg",
            },
          ],
        },
        {
          categoryId: 2,
          categoryName: "Soup",
          categoryIcon: "images/soup.png",
          isActiveTab: false,
          categoryItems: [
            {
              itemId: 5,
              itemName: "ITEM NAMES",
              itemDescription:
                "Item Description here, Item Description here, Item Description here, Item Description here, Item Description here,",
              itemPrice: 90.99,
              itemPriceCurency: "L.E",
              itemImg: "images/Meal.jpg",
            },
            {
              itemId: 6,
              itemName: "ITEM NAMES",
              itemDescription:
                "Item Description here, Item Description here, Item Description here, Item Description here, Item Description here,",
              itemPrice: 90.99,
              itemPriceCurency: "L.E",
              itemImg: "images/Meal.jpg",
            },
          ],
        },
        {
          categoryId: 3,
          categoryName: "Appetizers",
          categoryIcon: "images/appetizer.png",
          isActiveTab: false,
          categoryItems: [
            {
              itemId: 7,
              itemName: "ITEM NAMES",
              itemDescription:
                "Item Description here, Item Description here, Item Description here, Item Description here, Item Description here,",
              itemPrice: 90.99,
              itemPriceCurency: "L.E",
              itemImg: "images/Meal.jpg",
            },
          ],
        },
        {
          categoryId: 4,
          categoryName: "First Course",
          categoryIcon: "images/food-1.png",
          isActiveTab: false,
          categoryItems: [
            {
              itemId: 8,
              itemName: "ITEM NAMES",
              itemDescription:
                "Item Description here, Item Description here, Item Description here, Item Description here, Item Description here,",
              itemPrice: 90.99,
              itemPriceCurency: "L.E",
              itemImg: "images/Meal.jpg",
            },
            {
              itemId: 9,
              itemName: "ITEM NAMES",
              itemDescription:
                "Item Description here, Item Description here, Item Description here, Item Description here, Item Description here,",
              itemPrice: 90.99,
              itemPriceCurency: "L.E",
              itemImg: "images/Meal.jpg",
            },
          ],
        },
        {
          categoryId: 5,
          categoryName: "Main Course",
          categoryIcon: "images/food.png",
          isActiveTab: false,
          categoryItems: [{}],
        },
        {
          categoryId: 6,
          categoryName: "Dessert",
          categoryIcon: "images/cupcake.png",
          isActiveTab: false,
          categoryItems: [
            {
              itemId: 10,
              itemName: "ITEM NAMES",
              itemDescription:
                "Item Description here, Item Description here, Item Description here, Item Description here, Item Description here,",
              itemPrice: 90.99,
              itemPriceCurency: "L.E",
              itemImg: "images/Meal.jpg",
            },
            {
              itemId: 11,
              itemName: "ITEM NAMES",
              itemDescription:
                "Item Description here, Item Description here, Item Description here, Item Description here, Item Description here,",
              itemPrice: 90.99,
              itemPriceCurency: "L.E",
              itemImg: "images/Meal.jpg",
            },
          ],
        },
      ],
    }
  },
  components: {
   Swiper,
    SwiperSlide,
  } , methods: {
    getImageUrl (imageId) {
      return `https://picsum.photos/600/400/?image=${imageId}`
    },
    onSwiper (swiper) {
      console.log(swiper)
    },
    onSlideChange () {
      console.log('slide change')
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
